import QuizComponent from "components/quiz";
import React from "react";
import { getHomeOfficeQuizResultRoute } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";

const HomeOfficeQuizPage: React.FC = () => {
    useSetBodyClass(['layout-blue']);
    return (
        <div className="page-main">
            <QuizComponent id='2' afterQuizRoute={getHomeOfficeQuizResultRoute()} />
        </div>
    )
}

export default HomeOfficeQuizPage;

import VimeoPlayer from "components/vimeo-player";
import SplitBlueGreen from "layouts/split-blue-green";
import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeGameRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const HomeOfficeVideo1Page: React.FC = () => {
    return (
        <SplitBlueGreen>
            <div className="home-office-video-1">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Twoje biurko - Twoje małe królestwo
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_5} />
                    </div>

                </div>
                <nav>
                    <Link className="button on-green" to={getHomeOfficeGameRoute()}>Dalej</Link>
                </nav>
            </div>
        </SplitBlueGreen>
    )
}

export default HomeOfficeVideo1Page;

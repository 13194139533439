import MemoryGame from "components/memory-game";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { getHybridVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const HybridGamePage: React.FC = () => {
    useSetBodyClass(['layout-yellow']);

    const items: [string, string][] = [
        ['/memory/1.png', '/memory/1.png'],
        ['/memory/2.png', '/memory/2.png'],
        ['/memory/3.png', '/memory/3.png'],
        ['/memory/4.png', '/memory/4.png'],
        ['/memory/5.png', '/memory/5.png'],
        ['/memory/6.png', '/memory/6.png'],
    ];

    const [complete, setComplete] = useState(false);

    const onComplete = useCallback(() => {
        setComplete(true);
    }, [setComplete])

    return (
        <div className="hybrid-game-page">
            <p className="rules">Znadź pary</p>
            <div className="game-component">
                <MemoryGame
                    backImage="/memory/back.png"
                    items={items}
                    onComplete={onComplete}
                />
                {complete && <div className="jupi">
                    <p>Dobrze idzie Ci balansowanie pracy i w biurze i w domu. Gratulujemy. Czy wiesz jak pokonać bezpiecznie odległość pomiędzy nimi?</p>
                    <p> Zapraszamy w krótką podróż z naszym ambasadorem.</p></div>}
            </div>


            {complete && <nav>
                <Link className="button" to={getHybridVideo2Route()}>przejdź dalej</Link>
            </nav>}
        </div>
    )
}

export default HybridGamePage;

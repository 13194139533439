import QuizComponent from "components/quiz";
import React from "react";
import { getInOfficeQuizResultRoute } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";

const InOfficeQuizPage: React.FC = () => {

    useSetBodyClass(['layout-blue']);

    return (
        <div className="page-main">
            <QuizComponent id='1' afterQuizRoute={getInOfficeQuizResultRoute()} />
        </div>
    )
}

export default InOfficeQuizPage;

import * as PIXI from 'pixi.js'
import { GameConfig } from './game';
import { Sticker } from './sticker';

export default class Office extends PIXI.Application {
    private baseWidth: number;
    private baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;
    private resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, backgroundColor: number, private config: GameConfig) {
        super({
            width,
            height,
            transparent: true
        });

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        window.addEventListener('resize', this.gameResize)

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            [
                ['background', '/images/office-game/background.png'],
                ['k1', '/images/office-game/01.png'],
                ['k2', '/images/office-game/02.png'],
                ['k3', '/images/office-game/03.png'],
                ['k4', '/images/office-game/04.png'],
                ['k5', '/images/office-game/05.png'],
                ['k6', '/images/office-game/06.png'],
                ['k7', '/images/office-game/07.png'],
                ['k8', '/images/office-game/08.png']
            ].forEach(([key, src]) => {
                this.loader.add(key, src);
            })

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })

    }

    private initGame() {
        this.initBackground();
        this.initStickers();
    }

    private initBackground() {
        if (this.resources?.background) {
            const background = new PIXI.Sprite(this.resources.background.texture);

            this.stage.addChild(background);
        }
    }

    private initStickers() {
        if (this.resources) {
            const k = ['k1', 'k2', 'k3', 'k4', 'k5', 'k6', 'k7', 'k8'].map(key => {
                const sticker = new Sticker(
                    //@ts-ignore
                    this.resources[key].texture,
                    100, 100,
                    key
                )

                this.stage.addChild(sticker);

                return sticker
            })

            k[0]?.position.set(200, 200);
            k[1]?.position.set(200, 400);
            k[2]?.position.set(200, 600);
            k[3]?.position.set(400, 200);
            k[4]?.position.set(400, 500);
            k[5]?.position.set(1100, 500);
            k[6]?.position.set(700, 200);
            k[7]?.position.set(700, 600);
        }
    }

    public gameResize = () => {
        if (this.renderer) {
            const nW = this.config.container.offsetWidth;
            const nH = nW * this.baseRatio;

            this.renderer.resize(nW, nH);

            this.scaleRatio = (nW / this.baseWidth);

            this.stage.scale.set(this.scaleRatio)

        }
    }

    public getSceenBlob() {
        const data = this.renderer.extract.canvas(this.stage).toDataURL('image/png', 1);
        return data;
    }
}

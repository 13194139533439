import getScoreString from "components/quiz/get-score-string";
import SplitBlueYellow from "layouts/split-blue-yellow";
import useUserScore from "modules/user/hooks/use-user-score";
import React from "react";
import { Link } from "react-router-dom";
import { getInOfficeVideo3Route } from "routes/routes";
import './style.scss';

type Props = {}

const InOfficeQuizResult: React.FC<Props> = () => {
    const { score, max } = useUserScore('1');

    return (
        <SplitBlueYellow>
            <main className="in-office-quiz-result" >
                <p>Zdobyłaś/eś:<br />{getScoreString(score, max)}</p>
                <nav>
                    <Link className="button" to={getInOfficeVideo3Route()}>Dalej</Link>
                </nav>
            </main>
        </SplitBlueYellow>
    )
}

export default InOfficeQuizResult;

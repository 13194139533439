import React from "react";
import { Link } from "react-router-dom";
import { getHybridVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const HybridAfterGamePage: React.FC = () => {
    useSetBodyClass(['layout-red']);
    return (
        <main className="hybrid-after-game-page">
            <div className="text-column">
                <p>Dobrze idzie Ci balansowanie pracy i w biurze i w domu.</p>
                <p className="big">Gratulujemy.</p>
                <p>Czy wiesz jak pokonać bezpiecznie odległość pomiędzy nimi? Zapraszamy w krótką podróż z naszym ambasadorem. </p>
            </div>
            <div className="image-column">
                <img src="/images/img-4.png" alt="" />
                <nav>
                    <Link to={getHybridVideo2Route()} className="button">przejdź dalej</Link>
                </nav>
            </div>
        </main>
    )
}

export default HybridAfterGamePage;

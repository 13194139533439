import { useMemo } from "react";
import GameError from "utils/error";
import useGame from "./use-game";

export default function useQuizWithQuestions(id: QuizID) {
    const { isLoading, error, game } = useGame();

    const quiz = useMemo(() => {
        // Szukamy quizu o wskazanym ID
        const quiz = game?.quizzes?.find(q => q.id === id);

        if (!quiz) return null;

        return {
            ...quiz,
            questions: quiz?.questions
                // zamieniamy ID pytań na obiekty pytań
                .map(id => {
                    return game.quizQuestions?.find(q => q.id === id);
                })
                // usuwamy pytania których nie było w bazie
                .filter(Boolean)
        }
    }, [game, id]);

    // Jak jeszcze się ładuje to tylko przekazujemy dalej.
    if (isLoading) {
        return {
            isLoading
        }
    }

    // Jak pobranie danych było błędne to tylko to przekazujemy dalej.
    if (error) {
        return {
            isLoading,
            error
        }
    }

    if (quiz) {
        return {
            isLoading,
            quiz
        }
    } else {
        return {
            isLoading,
            error: new GameError(1200, `Unknown quiz: ${id}`)
        }
    }
}

import DeskGame from "components/desk-game";
import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const HomeOfficeGamePage: React.FC = () => {
    useSetBodyClass(['layout-blue']);
    return (
        <main className="page-main">
            <div className="home-office-game-page">
                <DeskGame>
                    <div className="game-rules">
                        <p>
                            Biuro czy dom? <strong>Praca zdalna</strong> dla jednych jest łatwa, dla innych <strong>rozdzielenie pracy i domu</strong> jest niemożliwe.
                        </p>
                        <p>
                            By pracować wydajnie i nie oszaleć musisz dbać o <strong>higienę przestrzeni.</strong> Posegreguj przedmioty tak, by pasowały do konkretnych kategorii.
                        </p>
                        <nav>
                            <Link className="button" to={getHomeOfficeVideo2Route()}>przejdź dalej</Link>
                        </nav>
                    </div>
                </DeskGame>
            </div>
        </main>
    )
}

export default HomeOfficeGamePage;

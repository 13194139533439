import SlideShow from "components/slide-show";
import SplitBlueYellow from "layouts/split-blue-yellow";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getInOfficeQuiz } from "routes/routes";
import './style.scss';

export default function InOfficeSlideShowPage() {

    const slides = useMemo(() => {
        return [
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_1.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_2.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_3.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_4.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_5.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_6.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_7.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_8.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_9.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_10.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_11.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_12.jpg'
            },
            {
                url: 'graCOVID_1920x1080_biurowa[1]1024_13.jpg'
            },
        ].map(s => {
            return {
                ...s,
                url: '/images/slide-shows/in-office/' + s.url
            }
        });
    }, [])

    const [showNavButtons, setNavButtons] = useState<{next: boolean, prev: boolean}>({next: false, prev: false})

    const slideShowRef = useRef<any>(null);

    const onNextClicked = useCallback(() => {
        if (slideShowRef.current) {
            slideShowRef.current.next();
        }
    }, [slideShowRef]);

    const onPrevClicked = useCallback(() => {
        if (slideShowRef.current) {
            slideShowRef.current.prev();
        }
    }, [slideShowRef]);

    const onSlideChange = useCallback((data) => {
        setNavButtons({
            next: data.nextAvaiable,
            prev: data.prevAvaiable
        })
    }, [setNavButtons])

    return <SplitBlueYellow>
        <div className='in-office-slide-show'>
            <div className="content">
                <div className="text-column">
                    <p>
                        Znamy wszyscy <strong>zasady</strong>.
                        Wiemy, gdzie i kiedy zakładać maskę, myć ręce dezynfekować swoje <strong>biurko</strong>.
                        Czy wiesz jednak jak faktycznie to działa?
                        Sprawdź wybierając jedną z <strong>dwóch ścieżek</strong>
                    </p>
                </div>
                <div className="slides-column">
                    <SlideShow slides={slides} ref={slideShowRef} onSlideChange={onSlideChange} />
                </div>

            </div>
            <nav>
                { showNavButtons.prev && <button className='button button-prev' onClick={onPrevClicked}>{'\u00A0'}</button>}
                { !showNavButtons.prev && <span className='button button-prev' style={{opacity: 0}}></span>}
                { showNavButtons.next && <button className='button button-next' onClick={onNextClicked}>{'\u00A0'}</button>}
                { !showNavButtons.next && <Link className='button' to={getInOfficeQuiz()}>Dalej</Link> }
            </nav>
        </div>
    </SplitBlueYellow>
}

import React from "react";
import './style.scss'

const SplitBlueYellow: React.FC = ({ children }) => {
    return (
        <div className="split-blue-yellow-background">
            <main className="page-main">
                {children}
            </main>
        </div>
    )
}

export default SplitBlueYellow;

import useQuizWithQuestions from "modules/game/hooks/use-quiz-with-question";
import React, { useCallback, useEffect, useReducer } from "react";
import { useHistory } from "react-router";
import QuizQuestionComponent from "./question";
import './style.scss';

type Props = {
    id: QuizID,
    afterQuizRoute: string
};

const QuizComponent: React.FunctionComponent<Props> = ({ id, afterQuizRoute }) => {
    const { isLoading, error, quiz } = useQuizWithQuestions(id);

    const [activeQuestionIndex, setNextActiveQuistion] = useReducer((state: number) => {
        return state + 1;
    }, 0);

    const history = useHistory();

    const onNext = useCallback(() => {
        setNextActiveQuistion()
    }, [setNextActiveQuistion])

    useEffect(() => {
        if (!quiz) return;

        if (quiz.questions.length <= activeQuestionIndex) {
            history.push(afterQuizRoute);
        }
    }, [activeQuestionIndex, history, quiz, afterQuizRoute]);

    const question = quiz?.questions[activeQuestionIndex];

    const nextButton = <nav>
        <button className='button' onClick={onNext}>Przejdź dalej</button>
    </nav>;

    return (
        <>
            { isLoading && <p>Loading...</p>}
            { error && <p>ERROR: {error.message}</p>}
            {quiz && <div className="quiz">
                {question && <QuizQuestionComponent quizID={id} question={question} nextButton={nextButton} />}
            </div>}

        </>
    )
}

export default QuizComponent;

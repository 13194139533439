import makeStore from "@mborecki/react-create-store";
import reducer from "./reducer";

export type GameModuleState = {
    isLoading: boolean,
    game?: Game
}

const initGameModuleState = {
    isLoading: false,
    text: 'init-text'
}

export enum GAME_MODULE_ACTION {
    FETCH = "FETCH",
    FETCH_SUCCESS = "FETCH_SUCCESS"
}

export type DemoModuleAction = {
    type: GAME_MODULE_ACTION,
    payload?: any
}

const [
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
] = makeStore(reducer, initGameModuleState)


export {
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
}

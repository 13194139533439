import VimeoPlayer from "components/vimeo-player";
import SplitBlueGreen from "layouts/split-blue-green";
import React from "react";
import { Link } from "react-router-dom";
import { getContaktRoute, getWorkStyleRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const HomeOfficeVideo3Page: React.FC = () => {
    return (
        <SplitBlueGreen>
            <div className="home-office-video-3">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Świetnie radzisz sobie z pracą w domu. Jeśli jednak miałbyś ochotę na zmianę, powrót do biura lub pracę w modelu 50/50 zacznij raz jeszcze naszą grę wybierając jeden z dwóch powyższych modeli. Masz nadal pytania o pracę w domu? Chętnie odpowiemy. Zapraszamy do kontaktu.
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_8} />
                    </div>
                </div>
                <nav>
                    <Link className="button" to={getWorkStyleRoute()}>zacznij jeszcze raz</Link>
                    <Link className="button on-green" to={getContaktRoute()}>skontaktuj się z nami</Link>
                </nav>

            </div>
        </SplitBlueGreen>
    )
}

export default HomeOfficeVideo3Page;

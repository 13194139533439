import { useEffect } from "react";

/**
 * Hook ustawiający klase elementu body. Automatycznie usuwa klasę przy usuwaniu komponentu
 *
 * @example
 *
 * const Component = () => {
 *   useSetBodyClass('class-1', 'class-2');
 *
 *  return (
 *      <p>Ten komponent będzie ustawiał klasę BODY</p>
 *  )
 * }
 */
export default function useSetBodyClass(classes: string[]) {
    useEffect(() => {
        classes.forEach((cl) => {
            document.body.classList.add(cl);
        })

        return () => {
            classes.forEach((cl) => {
                document.body.classList.remove(cl);
            })
        }
    }, [classes])
}

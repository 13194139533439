import VimeoPlayer from "components/vimeo-player";
import SplitBlueRed from "layouts/split-blue-red";
import React from "react";
import { Link } from "react-router-dom";
import { getHybridExperimentRoute, getHybridSlideShowRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const HybridVideo2Page: React.FC = () => {
    return (
        <SplitBlueRed>
            <div className="hybrid-video-2">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Z biura do domu z domu do biura. Planowanie wyprawy.
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_10} />
                    </div>

                </div>
                <nav>
                    <Link className="button" to={getHybridExperimentRoute()}>opowieść</Link>
                    <Link className="button on-pink" to={getHybridSlideShowRoute()}>Pokaz</Link>
                </nav>
            </div>
        </SplitBlueRed>
    )
}

export default HybridVideo2Page;

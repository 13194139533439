import { GameModuleState, DemoModuleAction, GAME_MODULE_ACTION } from ".";

export default function UserReducer(state: GameModuleState, action: DemoModuleAction): GameModuleState {
    switch (action.type) {
        case GAME_MODULE_ACTION.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                game: action.payload.game
            }

        case GAME_MODULE_ACTION.FETCH:
            return {
                isLoading: true
            }
    }

    return state;
}

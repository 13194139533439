import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const HomeOfficeAfterGamePage: React.FC = () => {
    useSetBodyClass(['layout-green']);
    return (
        <main className="home-office-after-game-page">
            <div className="text-column">
                <p>Widzimy, że dobrze balansujesz swoje życie.</p>
                <p className="big">To nie koniec zabawy.</p>
                <p>Zobacz jak z zachowaniem równowagi życiowej i służbowej radzi sobie nasz ambasador.</p>
            </div>
            <div className="image-column">
                <img src="/images/img-3.png" alt="" />
                <nav>
                    <Link to={getHomeOfficeVideo2Route()} className="button">przejdź dalej</Link>
                </nav>
            </div>
        </main>
    )
}

export default HomeOfficeAfterGamePage;

export function getGameNodeRoute(id = ':id') {
    return `/node/${id}`;
}

export function getIntroRoute() {
    return `/intro`;
}

export function getIntro2Route() {
    return `/intro-2`;
}

export function getWorkStyleRoute() {
    return `/work-style`;
}

export function getInOfficeVideo1Route() {
    return '/in-office/video-1';
}

export function getInOfficeGameRoute() {
    return '/in-office/game';
}

export function getInOfficeAfterGameRoute() {
    return '/in-office/after-game';
}

export function getInOfficeVideo2Route() {
    return '/in-office/video-2';
}

export function getInOfficeExperimentRoute() {
    return '/in-office/experiment';
}

export function getInOfficeSlideShowRoute() {
    return '/in-office/slide-show';
}

export function getInOfficeQuiz() {
    return '/in-office/quiz';
}

export function getInOfficeQuizResultRoute() {
    return '/in-office/result';
}

export function getInOfficeVideo3Route() {
    return '/in-office/video-3';
}


export function getHybridVideo1Route() {
    return '/hybrid/video-1';
}

export function getHybridGameRoute() {
    return '/hybrid/game';
}

export function getHybridAfterGameRoute() {
    return '/hybrid/after-game';
}

export function getHybridVideo2Route() {
    return '/hybrid/video-2';
}

export function getHybridExperimentRoute() {
    return '/hybrid/experiment';
}

export function getHybridSlideShowRoute() {
    return '/hybrid/slide-show';
}

export function getHybridQuiz() {
    return '/hybrid/quiz';
}

export function getHybridQuizResultRoute() {
    return '/hybrid/result';
}

export function getHybridVideo3Route() {
    return '/hybrid/video-3';
}



export function getHomeOfficeVideo1Route() {
    return '/home-office/video-1';
}

export function getHomeOfficeGameRoute() {
    return '/home-office/game';
}

export function getHomeOfficeAfterGameRoute() {
    return '/home-office/after-game';
}

export function getHomeOfficeVideo2Route() {
    return '/home-office/video-2';
}

export function getHomeOfficeExperimentRoute() {
    return '/home-office/experiment';
}

export function getHomeOfficeSlideShowRoute() {
    return '/home-office/slide-show';
}

export function getHomeOfficeQuizRoute() {
    return '/home-office/quiz';
}

export function getHomeOfficeQuizResultRoute() {
    return '/home-office/result';
}

export function getHomeOfficeVideo3Route() {
    return '/home-office/video-3';
}


export function getContaktRoute() {
    return '/kotakt'
}

import LowerYellowLayout from "layouts/lower-yellow";
import React from "react"
import { Link } from "react-router-dom";
import { getWorkStyleRoute } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class"
import './style.scss';

const Intro2Page: React.FunctionComponent = () => {

    useSetBodyClass(['layout-blue'])

    return (<>
        <LowerYellowLayout>
            <main className="page-main">
                <div className="intro-2-page">
                    <div className="column">
                        <div className="inner">
                            <p>A Ty w jakim modelu aktualnie pracujesz?</p>
                            <nav>
                                <Link className="button" to={getWorkStyleRoute()}>przejdź dalej</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </LowerYellowLayout>
    </>)
}

export default Intro2Page;

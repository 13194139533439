import SplitRedBlue from "layouts/split-red-blue";
import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeVideo1Route, getHybridVideo1Route, getInOfficeVideo1Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const WorkStylePage: React.FC = () => {

    useSetBodyClass(['layout-blue']);
    return (
        <SplitRedBlue>
            <div className="work-style-page">
                <div className="column-text">
                    <div className="wrapper">
                        <h2>A Ty w jakim modelu aktualnie pracujesz?</h2>
                        <p>Pracujesz w domu? Codziennie pojawiasz się w swoim biurze? A może pracujesz w <strong>modelu 50/50</strong>?  Zależnie od wybranego modelu codziennie stawiane są na Twojej drodze wyzwania. <strong>Wybierz swój model</strong>, wypracuj swoją ścieżkę organizacji pracy i zachowaj życiowy balans.</p>
                    </div>
                </div>
                <div className="column-nav">
                    <nav>
                        <div className="choise choise-1">
                            <Link to={getInOfficeVideo1Route()} className="img">

                            </Link>
                            <Link to={getInOfficeVideo1Route()} className="label">
                                stacjonarnie
                            </Link>
                        </div>
                        <div className="choise choise-2">
                            <Link to={getHomeOfficeVideo1Route()} className="img">
                            </Link>
                            <Link to={getHomeOfficeVideo1Route()} className="label">
                                zdalnie
                            </Link>
                        </div>
                        <div className="choise choise-3">
                            <Link to={getHybridVideo1Route()} className="img">
                            </Link>
                            <Link to={getHybridVideo1Route()} className="label">
                                hybrydowo
                            </Link>
                        </div>
                    </nav>
                </div>
            </div>
        </SplitRedBlue>
    )
}

export default WorkStylePage;

import React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
    image: string,
    back: string,
    isHidden: boolean,
    isRemoved: boolean,
    isMismatched: boolean,
    onSelected: () => void
}

const rotation = keyframes`
0% {
    transform: rotate(0);
}
25% {
    transform: rotate(20deg);
}
75% {
    transform: rotate(-20deg);
}
100% {
    transform: rotate(0);
}
`;

const TileContainer = styled.div`
position: relative;

.tile-image {
    display: block;
    width: 90%;

    padding: 5%;

    transition: .3s linear;

    &:hover {
        // background: #9d9d9d;
        transform: scale(0.9);
    }

    &.removed {
        transition: 1s linear;
        opacity: 0;
    }

    &.mismatched {
        animation: ${rotation} 1s;
    }
}`;

export default function MemoryTile({ image, back, isHidden, isRemoved, isMismatched, onSelected }: Props) {
    return (
        <TileContainer className="tile-container">
            <img
                className={`tile-image ${isRemoved ? 'removed' : ''} ${isMismatched ? 'mismatched' : ''} ${isHidden ? 'hidden' : ''}`}
                src={isHidden ? back : image}
                onClick={onSelected}
                alt="TODO"
            />
        </TileContainer>
    )
}

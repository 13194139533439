import useQuizWithQuestions from "modules/game/hooks/use-quiz-with-question";
import { useMemo } from "react";
import { useUserModuleState } from "..";

export default function useUserScore(id: string) {
    const { quizAnswers } = useUserModuleState();
    const { quiz, isLoading } = useQuizWithQuestions(id);

    const score = useMemo(() => {

        if (quizAnswers && quiz) {
            return quizAnswers.reduce((prev: number, next) => {

                const question = quiz?.questions.find(q => {
                    return q?.id === next.question;
                })

                if (question) {
                    const answer = question.answers.find(a => {
                        return a.id === next.answer;
                    });

                    if (answer) {
                        return answer.correct ? prev + 1 : prev;
                    }
                }

                return prev;
            }, 0)
        }

        return 0;
    }, [quizAnswers, quiz])


    return {
        isLoading,
        score,
        max: quiz?.questions.length || 0
    }
}

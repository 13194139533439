import React from "react";
import './style.scss'

const SplitRedBlue: React.FC = ({ children }) => {
    return (
        <div className="split-red-blue-background">
            <main className="page-main">
                {children}
            </main>
        </div>
    )
}

export default SplitRedBlue;

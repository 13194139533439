import VimeoPlayer from "components/vimeo-player";
import SplitBlueYellow from "layouts/split-blue-yellow";
import React from "react";
import { Link } from "react-router-dom";
import { getContaktRoute, getWorkStyleRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const InOfficeVideo3Page: React.FC = () => {
    return (
        <SplitBlueYellow>
            <div className="in-office-video-3">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Znamy wszyscy <strong>zasady</strong>.<br /> Wiemy, gdzie i kiedy zakładać maskę, myć ręce, dezynfekować swoje <strong>biurko</strong>. Czy wiesz jednak jak faktycznie to działa? Sprawdź wybierając jedną z <strong>dwóch ścieżek.</strong>
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_4} />
                    </div>

                </div>
                <nav>
                    <Link className="button" to={getWorkStyleRoute()}>zacznij jeszcze raz</Link>
                    <Link className="button" to={getContaktRoute()}>skontaktuj się z nami</Link>
                </nav>
            </div>
        </SplitBlueYellow>
    )
}

export default InOfficeVideo3Page;

import VimeoPlayer from 'components/vimeo-player';
import SplitBlueYellow from 'layouts/split-blue-yellow';
import React from 'react';
import { Link } from 'react-router-dom';
import { getInOfficeQuiz } from 'routes/routes';
import { VIDEO } from 'video';
import './style.scss';

export default function InOfficeExperimentPage() {
    return <SplitBlueYellow>
        <div className="in-office-experiment">
            <div className="content">
                <div className="video-column">
                    <VimeoPlayer videoId={VIDEO.COVID_3} />
                </div>

            </div>
            <nav>
                <Link className="button" to={getInOfficeQuiz()}>Dalej</Link>
            </nav>
        </div>
    </SplitBlueYellow>
}

import styled from "styled-components";
import React from "react";

type Props = {
    image: string,
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`;

const GameItem: React.FunctionComponent<Props> = ({ image }) => {

    return (
        <Container>
            <img src={image} alt="" />
        </Container>
    )
}

export default GameItem;

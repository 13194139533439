import React from "react";
import './style.scss';

type Props = {};

const PageHeader: React.FunctionComponent<Props> = () => {
    return (
        <header className="page-header">
            <h1>LOGO</h1>
        </header>
    )
}

export default PageHeader;

export enum VIDEO {
    COVID_1 = 481125341,
    COVID_2 = 481125411,
    COVID_3 = 481125533,
    COVID_4 = 481125784,
    COVID_5 = 481125820,
    COVID_6 = 481125939,
    COVID_7 = 481126001,
    COVID_8 = 481126155,
    COVID_9 = 481126194,
    COVID_10 = 481126273,
    COVID_11 = 481126326,
    COVID_12 = 481126492,
}

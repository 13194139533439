import useSaveUserAnswer from "modules/user/hooks/use-save-user-answer";
import React, { useCallback } from "react";

type Props = {
    question: QuizQuestion,
    quizID: QuizID,
    nextButton: any
}

const QuizQuestionComponent: React.FunctionComponent<Props> = ({ question, quizID, nextButton }) => {

    const saveUserAnswer = useSaveUserAnswer();

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            saveUserAnswer(quizID, question.id, e.target.value);
        }
    }, [quizID, question.id, saveUserAnswer])

    return (
        <div className="quiz-question">
            <div className="question-column">
                <img src="/images/img-2.png" alt="" />
            </div>
            <div className="answers-column">

                <div className="wrapper">
                    <div className='question'>
                        <p>
                            {question.text}
                        </p>
                    </div>
                    <div className="answer-list">
                        {question.answers.map((a) => {
                            return (
                                <label className="answer" key={question.id + a.id}>
                                    <input type="radio" name={`answer_${question.id}`} value={a.id} onChange={onChange} />
                                    <span>{a.text}</span>
                                </label>
                            )
                        })}
                    </div>
                    {nextButton}
                </div>
            </div>
        </div>
    )
}

export default QuizQuestionComponent;

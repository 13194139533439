import VimeoPlayer from "components/vimeo-player";
import SplitBlueYellow from "layouts/split-blue-yellow";
import React from "react";
import { Link } from "react-router-dom";
import { getInOfficeGameRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const InOfficeVideo1Page: React.FC = () => {
    return (
        <SplitBlueYellow>
            <div className="in-office-video-1">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Co jest najważniejsze w czasach pandemii? Czy Twoje biurko jest niebezpieczne?
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_1} />
                    </div>

                </div>
                <nav>
                    <Link className="button" to={getInOfficeGameRoute()}>Dalej</Link>
                </nav>
            </div>
        </SplitBlueYellow>
    )
}

export default InOfficeVideo1Page;

import DropZone from "components/drag-drop-template/drop-zone";
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import GameColumn from "./game-column";
import { item5, item6, item7, item8, item9, item1, item2, item3, item4, item10, item11, item12, item13, item14, item15 } from "./items";
import './style.scss';

type Props = {}

type Item = {
    id: string,
    image: string,
    groupId: string,
    gridWidth: number,
    gridHeight: number
}

export type ItemWithPosition = Item & {
    top: number,
    left: number
}

type GameData = { work: ItemWithPosition[], home: ItemWithPosition[] }

const DeskGame: React.FC<Props> = ({ children }) => {

    const initData: GameData = useMemo(() => {
        return {
            work: [
                item5,
                item6,
                item7,
                item8,
                item9,
                item15
            ].map(d => {
                return {
                    ...d,
                    top: 0,
                    left: 0
                }
            }),
            home: [
                item1,
                item2,
                item3,
                item4,
                item10,
                item11,
                item12,
                item13,
                item14
            ].map(d => {
                return {
                    ...d,
                    top: 0,
                    left: 0
                }
            })
    }}, []);

    const [data, moveItem] = useReducer((state: GameData, { pickupData, dropId }: { pickupData: string, dropId: string }) => {
        console.log('moveItem', { pickupData, dropId });

        const item = [...state.work, ...state.home].find(i => i.id === pickupData);

        const result = {
            work: state.work.filter(i => i.id !== pickupData),
            home: state.home.filter(i => i.id !== pickupData)
        }

        if (item) {
            if (dropId === 'work') {
                result.work.push(item);
            }

            if (dropId === 'home') {
                result.home.push(item);
            }
        }

        return result;

    }, initData);

    const dropZoneRef = useRef<HTMLDivElement>(null);
    const [dropZoneColCount, setDropZoneColCount] = useState(5);
    const [gridScale, setGridScale] = useState(16);

    const onWindowResize = useCallback(() => {
        const newColCount = 20;
        setDropZoneColCount(newColCount);

        if (dropZoneRef.current) {
            setGridScale(Math.floor(dropZoneRef.current.offsetWidth / newColCount));
        }

    }, [setDropZoneColCount, dropZoneRef]);

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
    }, [onWindowResize])

    const onDrop = useCallback((pickupData: string, dropId: string) => {
        moveItem({ pickupData, dropId })
    }, [moveItem])

    return (
        <div className="desk-game">
            <div className="desk-region region-work" ref={dropZoneRef}>
                <h2>Praca</h2>
                <DropZone id="work" onDrop={onDrop}>
                    <GameColumn
                        gridScale={gridScale}
                        items={data.work}
                        gridWidth={dropZoneColCount}
                    />
                </DropZone>
            </div>
            <div className="game-info">
                {children}
            </div>
            <div className="desk-region region-home">
                <h2>Dom</h2>
                <DropZone id="home" onDrop={onDrop}>
                    <GameColumn
                        gridScale={gridScale}
                        items={data.home}
                        gridWidth={dropZoneColCount}
                    />
                </DropZone>
            </div>
        </div>
    )
}


export default DeskGame;

import React from 'react';
import { Link } from 'react-router-dom';
import { getIntro2Route } from 'routes/routes';
import useSetBodyClass from 'utils/hooks/use-set-body-class';
import './style.scss';

/**
 * Strona główna
 */
export default function HomePage() {

    useSetBodyClass(['layout-blue']);

    return (
        <main className="page-main welcome-page">
            <div className="content">

                <div className="text">
                    <h2>Znajdź swój balans</h2>
                </div>

                <div className="img">
                    <img src="/images/home/bg.png" alt="Znajdź swój balans" />
                </div>
            </div>
            <nav>
                <Link to={getIntro2Route()} className="button">sprawdź <img src="/images/arrow.png" alt="arrow" /> odkryj, doświadcz, zobacz</Link>
            </nav>
        </main>
    )
}

import VimeoPlayer from "components/vimeo-player";
import SplitBlueRed from "layouts/split-blue-red";
import React from "react";
import { Link } from "react-router-dom";
import { getContaktRoute, getWorkStyleRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const HybridVideo3Page: React.FC = () => {
    return (
        <SplitBlueRed>
            <div className="hybrid-video-3">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Świat wokół zmieniają się bardzo dynamicznie, więc może się okazać, że skierujemy Cię do pracy z domu lub ponownie wszyscy bez wyjątku będziemy mogli wrócić do biura. Poznaj inne modele i zacznij grę raz jeszcze wybierając inną ścieżkę.<br />
                            Jeśli chciałbyś dowiedzieć się więcej o zasadach naszego biura napisz do nas. Chętnie odpowiemy na Twoje pytania.

                    </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_12} />
                    </div>
                </div>
                <nav>
                    <Link className="button" to={getWorkStyleRoute()}>zacznij jeszcze raz</Link>
                    <Link className="button on-pink" to={getContaktRoute()}>skontaktuj się z nami</Link>
                </nav>

            </div>
        </SplitBlueRed>
    )
}

export default HybridVideo3Page;

import getScoreString from "components/quiz/get-score-string";
import SplitBlueGreen from "layouts/split-blue-green";
import useUserScore from "modules/user/hooks/use-user-score";
import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeVideo3Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

type Props = {}

const HomeOfficeQuizResult: React.FC<Props> = () => {
    useSetBodyClass(['layout-green']);
    const {score, max} = useUserScore('2');

    return (
        <SplitBlueGreen>
            <main className="home-office-quiz-result" >
                <p>Zdobyłaś/eś:<br />{getScoreString(score, max)}</p>
                <nav>
                    <Link className="button on-green" to={getHomeOfficeVideo3Route()}>Dalej</Link>
                </nav>
            </main>
        </SplitBlueGreen>
    )
}

export default HomeOfficeQuizResult;

const item1 = {
    id: '1',
    image: "/images/desk-game/game-pad.png",
    groupId: 'home',
    gridWidth: 8,
    gridHeight: 4
}
const item2 = {
    id: '2',
    image: "/images/desk-game/pencil.png",
    groupId: 'work',
    gridWidth: 3,
    gridHeight: 9
}
const item3 = {
    id: '3',
    image: "/images/desk-game/game-boy.png",
    groupId: 'home',
    gridWidth: 5,
    gridHeight: 7
}
const item4 = {
    id: '4',
    image: "/images/desk-game/glases.png",
    groupId: 'work',
    gridWidth: 3,
    gridHeight: 6
}
const item5 = {
    id: '5',
    image: "/images/desk-game/notepad.png",
    groupId: 'work',
    gridWidth: 7,
    gridHeight: 9
}
const item6 = {
    id: '6',
    image: "/images/desk-game/laptop.png",
    groupId: 'work',
    gridWidth: 11,
    gridHeight: 7
}
const item7 = {
    id: '7',
    image: "/images/desk-game/knife.png",
    groupId: 'home',
    gridWidth: 6,
    gridHeight: 5
}
const item8 = {
    id: '8',
    image: "/images/desk-game/tea-pot.png",
    groupId: 'work',
    gridWidth: 9,
    gridHeight: 6
}
const item9 = {
    id: '9',
    image: "/images/desk-game/cup.png",
    groupId: 'work',
    gridWidth: 3,
    gridHeight: 5
}
const item10 = {
    id: '10',
    image: "/images/desk-game/weights.png",
    groupId: 'home',
    gridWidth: 13,
    gridHeight: 6
}
const item11 = {
    id: '11',
    image: "/images/desk-game/coffe.png",
    groupId: 'work',
    gridWidth: 6,
    gridHeight: 7
}
const item12 = {
    id: '12',
    image: "/images/desk-game/camera.png",
    groupId: 'home',
    gridWidth: 7,
    gridHeight: 5
}
const item13 = {
    id: '13',
    image: "/images/desk-game/mouse.png",
    groupId: 'work',
    gridWidth: 4,
    gridHeight: 6
}
const item14 = {
    id: '14',
    image: "/images/desk-game/pen.png",
    groupId: 'work',
    gridWidth: 2,
    gridHeight: 8
}
const item15 = {
    id: '15',
    image: "/images/desk-game/bottle.png",
    groupId: 'work',
    gridWidth: 5,
    gridHeight: 11
}


export {
    item1,
    item2,
    item3,
    item4,
    item5,
    item6,
    item7,
    item8,
    item9,
    item10,
    item11,
    item12,
    item13,
    item14,
    item15
}

import VimeoPlayer from "components/vimeo-player";
import SplitBlueYellow from "layouts/split-blue-yellow";
import React from "react";
import { Link } from "react-router-dom";
import { getInOfficeExperimentRoute, getInOfficeSlideShowRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss';

const InOfficeVideo2Page: React.FC = () => {
    return (
        <SplitBlueYellow>
            <div className="in-office-video-2">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Znamy wszyscy <strong>zasady</strong>.<br /> Wiemy, gdzie i kiedy zakładać maskę, myć ręce, dezynfekować swoje <strong>biurko</strong>. Czy wiesz jednak jak faktycznie to działa? Sprawdź wybierając jedną z <strong>dwóch ścieżek.</strong>
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_2} />
                    </div>

                </div>
                <nav>
                    <Link className="button" to={getInOfficeExperimentRoute()}>opowieść</Link>
                    <Link className="button" to={getInOfficeSlideShowRoute()}>Pokaz</Link>
                </nav>
            </div>
        </SplitBlueYellow>
    )
}

export default InOfficeVideo2Page;

import PickUp from "components/drag-drop-template/pick-up";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ItemWithPosition } from ".";
import GameItem from "./game-item";

type Props = {
    gridScale: number,
    gridWidth: number,
    items: ItemWithPosition[]
}

const Container = styled.div`
    flex: 1 0 0;
    position: relative;

    .item-container {
        position: absolute;
    }
`;

const GameColumn: React.FC<Props> = ({ gridScale, items, gridWidth }) => {
    const sortItems = useCallback((items: ItemWithPosition[]) => {
        return items.reduce<ItemWithPosition[]>((prev, next) => {

            if (prev.length === 0) {
                return [
                    ...prev,
                    {
                        ...next,
                        top: 0,
                        left: 0
                    }
                ]
            }

            const lowestItem = prev.reduce<ItemWithPosition | null>(
                (prev, next) => {
                    if (!prev) {
                        return next;
                    }

                    if (next.top + next.gridHeight > (prev.top + prev.gridHeight)) {
                        return next;
                    }

                    return prev;
                },
                null
            );

            const lastItem = prev[prev.length - 1];

            // Jeżeli ostatni element jest jednocześnie najniżej...
            if (lowestItem === lastItem) {
                //...i nowy element nie zmieści się na szerokość...
                if (lastItem.left + lastItem.gridWidth + next.gridWidth > gridWidth) {
                    // ...to dodajemy go pod najniższym elementem od zera!
                    return [
                        ...prev,
                        {
                            ...next,
                            top: lastItem.top + lastItem.gridHeight,
                            left: 0
                        }
                    ]
                } else {
                    //
                    return [
                        ...prev,
                        {
                            ...next,
                            top: lastItem.top,
                            left: lastItem.left + lastItem.gridWidth,
                        }
                    ]
                }
            } else {
                if (lastItem.left + lastItem.gridWidth + next.gridWidth > gridWidth) {
                    return [
                        ...prev,
                        {
                            ...next,
                            top: lowestItem ? lowestItem.top + lowestItem.gridHeight : 0,
                            left: 0
                        }
                    ]
                } else {
                    return [
                        ...prev,
                        {
                            ...next,
                            top: lowestItem ? lowestItem.top : 0,
                            left: lastItem.left + lastItem.gridWidth,
                        }
                    ]
                }
            }
        }, [])
    }, [gridWidth]);

    const sortedItems = useMemo(() => {
        return sortItems(items);
    }, [items, sortItems])

    const meHeight = useMemo(() => {
        const lowestItem = sortedItems.reduce<ItemWithPosition | null>(
            (prev, next) => {
                if (!prev) {
                    return next;
                }

                if (next.top + next.gridHeight > (prev.top + prev.gridHeight)) {
                    return next;
                }

                return prev;
            },
            null
        );

        if (lowestItem) {
            return lowestItem.top + lowestItem.gridHeight;
        }

        return 0;
    }, [sortedItems])


    return (
        <Container style={{ minHeight: meHeight * gridScale }}>
            {
                sortedItems.map((i, index) => {
                    return (
                        <div key={i.image} className="item-container" style={{
                            width: i.gridWidth * gridScale,
                            height: i.gridHeight * gridScale,
                            top: i.top * gridScale,
                            left: i.left * gridScale
                        }}>
                            <PickUp data={i.id}>
                                <GameItem image={i.image} />
                            </PickUp>
                        </div>
                    )
                })
            }
        </Container>
    )
}

export default GameColumn;

import VimeoPlayer from 'components/vimeo-player';
import SplitBlueRed from 'layouts/split-blue-red';
import React from 'react';
import { Link } from 'react-router-dom';
import { getHybridQuiz } from 'routes/routes';
import { VIDEO } from 'video';
import './style.scss';

export default function HybridExperimentPage() {
    return <SplitBlueRed>
        <div className="hybrid-experiment">
            <div className="content">
                <div className="content">
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_11} />
                    </div>

                </div>

            </div>
            <nav>
                <Link className="button on-pink" to={getHybridQuiz()}>Dalej</Link>
            </nav>
        </div>
    </SplitBlueRed>
}

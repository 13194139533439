import OfficeGame from "components/office-game";
import React from "react";
import { Link } from "react-router-dom";
import { getInOfficeVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const InOfficeGamePage: React.FC = () => {

    useSetBodyClass(['layout-blue']);

    return (
        <main className="page-main" >
            <div className="in-offige-game-page">
                <div className="game-rules">
                    <p>
                        Ustaw swoje biuro. Przygotowane elementy trzeba rozplanować tak by zachować bezpieczeństwo.
                    </p>
                    <nav className="primary">
                        <Link className="button" to={getInOfficeVideo2Route()}>Dalej</Link>
                    </nav>
                </div>
                <div className="game">
                    <OfficeGame />
                </div>
                <nav className="secondary">
                        <Link className="button" to={getInOfficeVideo2Route()}>Dalej</Link>
                    </nav>
            </div>
        </main>
    )
}

export default InOfficeGamePage;

import { useCallback } from "react";
import { USER_MODULE_ACTION, useUserModuleDispatch } from "..";

export default function useSaveUserAnswer() {
    const dispatch = useUserModuleDispatch();

    return useCallback((quiz: QuizID, question: QuizQuiestionID, answer: QuizAnswerID | null) => {
        dispatch({
            type: USER_MODULE_ACTION.SAVE_QUIZ_ANSWERS,
            payload: {
                quiz,
                question,
                answer
            }
        })
    }, [dispatch])
}

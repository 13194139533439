import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import HomePage from './routes/intro-pages/home';
import { getContaktRoute, getHomeOfficeAfterGameRoute, getHomeOfficeExperimentRoute, getHomeOfficeGameRoute, getHomeOfficeQuizResultRoute, getHomeOfficeQuizRoute, getHomeOfficeSlideShowRoute, getHomeOfficeVideo1Route, getHomeOfficeVideo2Route, getHomeOfficeVideo3Route, getHybridAfterGameRoute, getHybridExperimentRoute, getHybridGameRoute, getHybridQuiz, getHybridQuizResultRoute, getHybridSlideShowRoute, getHybridVideo1Route, getHybridVideo2Route, getHybridVideo3Route, getInOfficeAfterGameRoute, getInOfficeExperimentRoute, getInOfficeGameRoute, getInOfficeQuiz, getInOfficeQuizResultRoute, getInOfficeSlideShowRoute, getInOfficeVideo1Route, getInOfficeVideo2Route, getInOfficeVideo3Route, getIntro2Route, getIntroRoute, getWorkStyleRoute } from 'routes/routes';
import './style.scss';
import PageHeader from 'components/page-header';

import IntroPage from 'routes/intro-pages/intro';
import WorkStylePage from 'routes/work-style';
import InOfficeVideo1Page from 'routes/in-office/video-1';
import InOfficeGamePage from 'routes/in-office/game';
import InOfficeAfterGamePage from 'routes/in-office/after-game';
import InOfficeVideo2Page from 'routes/in-office/video-2';
import InOfficeQuizPage from 'routes/in-office/quiz';
import InOfficeVideo3Page from 'routes/in-office/video-3';

import HybridVideo1Page from 'routes/hybrid/video-1';
import HybridGamePage from 'routes/hybrid/game';
import HybridAfterGamePage from 'routes/hybrid/after-game';
import HybridVideo2Page from 'routes/hybrid/video-2';
import HybridQuizPage from 'routes/hybrid/quiz';
import HybridVideo3Page from 'routes/hybrid/video-3';

import HomeOfficeVideo1Page from 'routes/home-office/video-1';
import HomeOfficeGamePage from 'routes/home-office/game';
import HomeOfficeAfterGamePage from 'routes/home-office/after-game';
import HomeOfficeVideo2Page from 'routes/home-office/video-2';
import HomeOfficeQuizPage from 'routes/home-office/quiz';
import HomeOfficeQuizResult from 'routes/home-office/quiz-result';
import HomeOfficeVideo3Page from 'routes/home-office/video-3';

import PageLayout from 'layouts/page-layout/intex';
import HybridQuizResult from 'routes/hybrid/quiz-result';
import InOfficeQuizResult from 'routes/in-office/quiz-result';
import InOfficeExperimentPage from 'routes/in-office/experiment';
import InOfficeSlideShowPage from 'routes/in-office/slide-show';
import HomeOfficeExperimentPage from 'routes/home-office/experiment';
import HomeOfficeSlideShowPage from 'routes/home-office/slide-show';
import HybridExperimentPage from 'routes/hybrid/experiment';
import HybridSlideShowPage from 'routes/hybrid/slide-show';
import ContactPage from 'routes/contact/contact';
import Intro2Page from 'routes/intro-pages/intro-2';

export default function App() {
    return (
        <PageLayout>
            <PageHeader />
            <div className="page">
                <BrowserRouter>
                    <Switch>
                        <Route path="/" component={HomePage} exact />
                        <Route path={getIntroRoute()} component={IntroPage} />
                        <Route path={getIntro2Route()} component={Intro2Page} />
                        <Route path={getWorkStyleRoute()} component={WorkStylePage} />


                        <Route path={getInOfficeVideo1Route()} component={InOfficeVideo1Page} />
                        <Route path={getInOfficeGameRoute()} component={InOfficeGamePage} />
                        <Route path={getInOfficeAfterGameRoute()} component={InOfficeAfterGamePage} />
                        <Route path={getInOfficeVideo2Route()} component={InOfficeVideo2Page} />
                        <Route path={getInOfficeExperimentRoute()} component={InOfficeExperimentPage}/>
                        <Route path={getInOfficeSlideShowRoute()} component={InOfficeSlideShowPage}/>
                        <Route path={getInOfficeQuiz()} component={InOfficeQuizPage} />
                        <Route path={getInOfficeQuizResultRoute()} component={InOfficeQuizResult} />
                        <Route path={getInOfficeVideo3Route()} component={InOfficeVideo3Page} />


                        <Route path={getHybridVideo1Route()} component={HybridVideo1Page} />
                        <Route path={getHybridGameRoute()} component={HybridGamePage} />
                        <Route path={getHybridAfterGameRoute()} component={HybridAfterGamePage} />
                        <Route path={getHybridVideo2Route()} component={HybridVideo2Page} />
                        <Route path={getHybridExperimentRoute()} component={HybridExperimentPage}/>
                        <Route path={getHybridSlideShowRoute()} component={HybridSlideShowPage}/>
                        <Route path={getHybridQuiz()} component={HybridQuizPage} />
                        <Route path={getHybridQuizResultRoute()} component={HybridQuizResult} />
                        <Route path={getHybridVideo3Route()} component={HybridVideo3Page} />


                        <Route path={getHomeOfficeVideo1Route()} component={HomeOfficeVideo1Page} />
                        <Route path={getHomeOfficeGameRoute()} component={HomeOfficeGamePage} />
                        <Route path={getHomeOfficeAfterGameRoute()} component={HomeOfficeAfterGamePage} />
                        <Route path={getHomeOfficeVideo2Route()} component={HomeOfficeVideo2Page} />
                        <Route path={getHomeOfficeExperimentRoute()} component={HomeOfficeExperimentPage}/>
                        <Route path={getHomeOfficeSlideShowRoute()} component={HomeOfficeSlideShowPage}/>
                        <Route path={getHomeOfficeQuizRoute()} component={HomeOfficeQuizPage} />
                        <Route path={getHomeOfficeQuizResultRoute()} component={HomeOfficeQuizResult} />
                        <Route path={getHomeOfficeVideo3Route()} component={HomeOfficeVideo3Page} />

                        <Route path={getContaktRoute()} component={ContactPage} />
                    </Switch>
                </BrowserRouter>
            </div>
        </PageLayout>
    )
}

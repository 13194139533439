import createStore from "@mborecki/react-create-store";
import reducer from "./reducer";

export type UserModuleState = {
    lastRoute?: string,
    quizAnswers?: {
        quiz: QuizID
        question: QuizQuiestionID,
        answer: QuizAnswerID
    }[]
}

const initUserModuleState: UserModuleState = {}

export enum USER_MODULE_ACTION {
    SAVE_LAST_ROUTE = 'SAVE_LAST_ROUTE',
    SAVE_QUIZ_ANSWERS = 'SAVE_QUIZ_ANSWERS'
}

export type UserModuleAction = {
    type: USER_MODULE_ACTION,
    payload?: any
}

const [
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
] = createStore(reducer, initUserModuleState, 'HORECA_C_DEMO_USER')


export {
    UserModuleProvider,
    useUserModuleDispatch,
    useUserModuleState
}

import React from "react";
import './style.scss'

const LowerYellowLayout: React.FC = ({children}) => {
    return (
        <div className="lower-yellow-background">
            {children}
        </div>
    )
}

export default LowerYellowLayout;

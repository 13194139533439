import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from "react";
import styled from "styled-components";

type Slide = {
    url: string
}

type Props = {
    slides: Slide[],
    onSlideChange?: (data: {nextAvaiable: boolean, prevAvaiable: boolean}) => void
}

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

type API = {

}

const SlideShow = forwardRef<API, Props>(

    ({ slides, onSlideChange }, ref) => {

        const [slideId, changeSlide] = useReducer((state: number, next: boolean) => {
            return next ? state+1 : state-1;
        }, 0);

        useImperativeHandle(ref, () => {
            return {
                next: () => {
                    changeSlide(true);
                },
                prev: () => {
                    changeSlide(false);
                }
            }
        });

        useEffect(() => {
            if (onSlideChange) {
                onSlideChange({
                    nextAvaiable: slideId < slides.length-1,
                    prevAvaiable: slideId > 0
                })
            }
        }, [slides, slideId, onSlideChange])

        const slide = slides[slideId];

        return <Container>
            {
                slide && <img src={slide.url} alt=""/>
            }
        </Container>
    }


)

export default SlideShow;

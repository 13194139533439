import { UserModuleState, UserModuleAction, USER_MODULE_ACTION } from ".";

export default function userReducer(state: UserModuleState, {type, payload}: UserModuleAction): UserModuleState {
    switch(type) {
        case USER_MODULE_ACTION.SAVE_LAST_ROUTE:
            return {
                ...state,
                lastRoute: payload.route
            }

        case USER_MODULE_ACTION.SAVE_QUIZ_ANSWERS:
            return {
                ...state,
                quizAnswers: [
                    ...(state.quizAnswers || []).filter(qa => {
                        return qa.quiz !== payload.quiz || qa.question !== payload.question;
                    }),
                    {
                        quiz: payload.quiz,
                        answer: payload.answer,
                        question: payload.question
                    }
                ]
            }
    }

    return state;
}

import React from "react";
import { Link } from "react-router-dom";
import { getWorkStyleRoute } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss'

export default function ContactPage() {

    useSetBodyClass(['layout-yellow']);

    return <main className="page-main contact-page">
        <div className="content">
            <div className="left-column">
                <h2>Skontaktuj się z nami!</h2>
                <p>
                    Zapraszamy do konktaktu<br />
                    i przedstwienia szczegółów projektu.
                </p>
                <nav>
                    <Link className="button" to={getWorkStyleRoute()}>zacznij jeszcze raz</Link>
                </nav>
            </div>

            <div className="right-column">
                <p className="company">Herc Creative Lab</p>
                <p className="phone">+48 509 909 541</p>
                <p className="mail">jacek.kizinski@herclab.agency</p>

                <p className="company">GD EVENTS</p>
                <p className="phone">+48 796 313 346</p>
                <p className="mail">kontakt@gdevents.pl</p>
            </div>

        </div>
    </main>
}

import React from "react";
import { Link } from "react-router-dom";
import { getInOfficeVideo2Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

const InOfficeAfterGamePage: React.FC = () => {

    useSetBodyClass(['layout-yellow']);

    return (
        <main className="in-office-after-game-page">
            <div className="text-column">
                <p>Udało Ci się nas rozstawić bezpiecznie po kątach.</p>
                <p className="big">Gratulacje!</p>
                <p>Pora na kolejne zadania.</p>
            </div>
            <div className="image-column">
                <img src="/images/img-1.png" alt="" />
                <nav>
                    <Link to={getInOfficeVideo2Route()} className="button">przejdź dalej</Link>
                </nav>
            </div>
        </main>
    )
}

export default InOfficeAfterGamePage;

import SlideShow from "components/slide-show";
import SplitBlueRed from "layouts/split-blue-red";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getHybridQuiz } from "routes/routes";
import './style.scss';

export default function HybridSlideShowPage() {

    const slides = useMemo(() => {
        return [
            {
                url: '0001.jpg'
            },
            {
                url: '0002.jpg'
            },
            {
                url: '0003.jpg'
            },
            {
                url: '0004.jpg'
            },
            {
                url: '0005.jpg'
            },
            {
                url: '0006.jpg'
            },
            {
                url: '0007.jpg'
            },
            {
                url: '0008.jpg'
            },
            {
                url: '0009.jpg'
            },
            {
                url: '0010.jpg'
            },
            {
                url: '0011.jpg'
            },
            {
                url: '0012.jpg'
            },
            {
                url: '0013.jpg'
            },
            {
                url: '0014.jpg'
            },
        ].map(s => {
            return {
                ...s,
                url: '/images/slide-shows/hybrid/' + s.url
            }
        });
    }, [])

    const [showNavButtons, setNavButtons] = useState<{next: boolean, prev: boolean}>({next: false, prev: false})

    const slideShowRef = useRef<any>(null);

    const onNextClicked = useCallback(() => {
        if (slideShowRef.current) {
            slideShowRef.current.next();
        }
    }, [slideShowRef]);

    const onPrevClicked = useCallback(() => {
        if (slideShowRef.current) {
            slideShowRef.current.prev();
        }
    }, [slideShowRef]);

    const onSlideChange = useCallback((data) => {
        setNavButtons({
            next: data.nextAvaiable,
            prev: data.prevAvaiable
        })
    }, [setNavButtons])

    return <SplitBlueRed>
        <div className='hybrid-slide-show'>
            <div className="content">
                <div className="text-column">
                    <p>
                        Znamy wszyscy <strong>zasady</strong>.
                        Wiemy, gdzie i kiedy zakładać maskę, myć ręce dezynfekować swoje <strong>biurko</strong>.
                        Czy wiesz jednak jak faktycznie to działa?
                        Sprawdź wybierając jedną z <strong>dwóch ścieżek</strong>
                    </p>
                </div>
                <div className="slides-column">
                    <SlideShow slides={slides} ref={slideShowRef} onSlideChange={onSlideChange} />
                </div>

            </div>
            <nav>
                { showNavButtons.prev && <button className='button on-pink button-prev' onClick={onPrevClicked}>{'\u00A0'}</button>}
                { !showNavButtons.prev && <span className='button on-pink button-prev' style={{opacity: 0}}></span>}
                { showNavButtons.next && <button className='button on-pink button-next' onClick={onNextClicked}>{'\u00A0'}</button>}
                { !showNavButtons.next && <Link className='button on-pink' to={getHybridQuiz()}>Dalej</Link> }
            </nav>
        </div>
    </SplitBlueRed>
}

import Office from "./office";

const BASE_WIDTH = 1366;
const BASE_HEIGHT = 800;

export type GameConfig = {
    container: HTMLElement
}

export default function createGame(config: GameConfig) {

    const app = new Office(BASE_WIDTH, BASE_HEIGHT, 0xfff8e6, config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        getSceenBlob: () => app.getSceenBlob(),
        uninstall: () => {
            app.destroy();
        }
    };
}

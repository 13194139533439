export default function getScoreString (score: number = 0, max: number = 0): string {

    if (score === 1) {
        return `1/${max} punkt`;
    }

    if (score > 0 && score < 4) {
        return `${score}/${max} punkty`;
    }

    return `${score}/${max} punktów`;
}

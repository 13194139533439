import VimeoPlayer from 'components/vimeo-player';
import SplitBlueGreen from 'layouts/split-blue-green';
import React from 'react';
import { Link } from 'react-router-dom';
import { getHomeOfficeQuizRoute } from 'routes/routes';
import { VIDEO } from 'video';
import './style.scss';

export default function HomeOfficeExperimentPage() {
    return <SplitBlueGreen>
        <div className="home-office-experiment">
            <div className="content">
                <div className="video-column">
                    <VimeoPlayer videoId={VIDEO.COVID_7} />
                </div>

            </div>
            <nav>
                <Link className="button on-green" to={getHomeOfficeQuizRoute()}>Dalej</Link>
            </nav>
        </div>
    </SplitBlueGreen >
}

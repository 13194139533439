import getScoreString from "components/quiz/get-score-string";
import SplitBlueRed from "layouts/split-blue-red";
import useUserScore from "modules/user/hooks/use-user-score";
import React from "react";
import { Link } from "react-router-dom";
import { getHybridVideo3Route } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class";
import './style.scss';

type Props = {}

const HybridQuizResult: React.FC<Props> = () => {
    useSetBodyClass(['layout-blue']);
    const {score, max} = useUserScore('3');

    return (
        <SplitBlueRed>
            <main className="hybrid-quiz-result" >
                <p>Zdobyłaś/eś:<br />{getScoreString(score, max)}</p>
                <nav>
                    <Link className="button on-pink" to={getHybridVideo3Route()}>Dalej</Link>
                </nav>
            </main>
        </SplitBlueRed>
    )
}

export default HybridQuizResult;

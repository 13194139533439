import { useCallback } from "react";
import { useQuery } from "react-query";
import { useGameModuleDispatch, GAME_MODULE_ACTION } from "..";
import makeRequest from "utils/network/make-request";
import GameError from "utils/error";

function getGame() {
    return makeRequest('/data.json').then(response => {
        return response.data;
    });
}

export default function useGame() {
    const dispatch = useGameModuleDispatch();

    const onSuccess = useCallback(data => {
        dispatch({
            type: GAME_MODULE_ACTION.FETCH_SUCCESS,
            payload: {
                game: data
            }
        })
    }, [dispatch])

    const { isFetching, data, error } = useQuery('game-query', getGame, {
        staleTime: Infinity,
        onSuccess
    })

    return {
        game: data as Game,
        error: error ? new GameError(1000, 'Game query error', error): undefined,
        isLoading: isFetching
    }
}

import VimeoPlayer from "components/vimeo-player";
import SplitBlueGreen from "layouts/split-blue-green";
import React from "react";
import { Link } from "react-router-dom";
import { getHomeOfficeExperimentRoute, getHomeOfficeSlideShowRoute } from "routes/routes";
import { VIDEO } from "video";
import './style.scss'

const HomeOfficeVideo2Page: React.FC = () => {
    return (
        <SplitBlueGreen>
            <div className="home-office-video-2">
                <div className="content">
                    <div className="text-column">
                        <p>
                            Znamy wszyscy <strong>zasady</strong>.<br /> Wiemy, gdzie i kiedy zakładać maskę, myć ręce, dezynfekować swoje <strong>biurko</strong>. Czy wiesz jednak jak faktycznie to działa? Sprawdź wybierając jedną z <strong>dwóch ścieżek.</strong>
                        </p>
                    </div>
                    <div className="video-column">
                        <VimeoPlayer videoId={VIDEO.COVID_6} />
                    </div>
                </div>
                <nav>
                    <Link className="button" to={getHomeOfficeExperimentRoute()}>opowieść</Link>
                    <Link className="button on-green" to={getHomeOfficeSlideShowRoute()}>Pokaz</Link>
                </nav>
            </div>
        </SplitBlueGreen>
    )
}

export default HomeOfficeVideo2Page;

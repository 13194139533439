import React from "react"
import { Link } from "react-router-dom";
import { getIntroRoute } from "routes/routes";
import useSetBodyClass from "utils/hooks/use-set-body-class"
import './style.scss';

const HomePage: React.FunctionComponent = () => {

    useSetBodyClass(['layout-blue'])

    return (<>
        <main className="page-main">
            <div className="home-page">
                <div className="text-column">
                    <h2>Narzędzie <span>{'\u00A0'}skutecznej{'\u00A0'} {'\u00A0'}i{'\u00A0'}angażującej{'\u00A0'}{'\u00A0'}komunikacji{'\u00A0'}</span> w{'\u00A0'}zasięgu ręki.</h2>
                    <p>
                        W miejsce standardowych prezentacji, długich maili zawierających
                        nowe standardy wewnętrzne, nieciekawych spotkań online i wirtualnych prezentacji możesz zaprezentować co tylko chcesz, korzystając z naszego
narzędzia. <strong>Składa się ono ze zróżnicowanych formatów przyciągających uwagę i utrwalających wiedzę. Dzięki nim zaprezentujesz nowe
 produkty, strategię firmy, przeprowadzisz motywacyjną grywalizację lub szkolenie z wybranego tematu.</strong> Możliwość wyboru ścieżki
dopasowanej do indywidualnych potrzeb odbiorcy oraz edukacja
przez rozrywkę to gwarancja skuteczności!
            </p>
                    <p className='bigger'>Jak to działa? Wybraliśmy jeden bieżący temat, na podstawie którego chcemy zaprezentować nasze narzędzie.</p>
                </div>
                <div className="img-column">
                    <Link className="button" to={getIntroRoute()}>przejdź dalej</Link>
                </div>
            </div>
        </main>
    </>)
}

export default HomePage;

import React, { useEffect, useRef } from "react";
import Vimeo from '@vimeo/player';

import './style.scss';

type Props = {
    /** ID video w systemie VIMEO */
    videoId: number,

    /** Czy automatycznie uruchomić odtwarzanie */
    autoplay?: boolean,

    /** Czy zapętlić odtwarzanie */
    loop?: boolean,

    /** Callback informujący o zmianie pozycji odtwarzania */
    onTimeUpdate?: (time: number) => void,

    /** Callback uruchamiany w momencie zakończenia filmu */
    onEnded?: () => void
}

/**
 * Komponent Playera Vimeo
 *
 * @param param0
 */
const VimeoPlayer: React.FunctionComponent<Props> = ({
    videoId,
    autoplay = true,
    loop = false,
    onTimeUpdate,
    onEnded
}) => {

    const player = useRef<Vimeo>();

    useEffect(() => {
        if (!player.current && !isNaN(videoId)) {
            player.current = new Vimeo('vimeo-player', {
                id: videoId,
                width: 715,
                portrait: false,
                title: false,
                byline: false,
                autoplay,
                controls: true,
                loop,
                responsive: true,
                muted: false,
            })
        } else {
            player.current?.getVideoId()
                .then(id => {
                    if (id !== videoId) {
                        if (player.current)
                            player.current.loadVideo(videoId);
                    }
                })
        }

        if (player.current) {

            player.current.setVolume(1);

            player.current.on('playing', () => {
            })

            player.current.on('loaded', async (e: any) => {})

            player.current.on('pause', () => {
            })

            player.current.on('timeupdate', ({ seconds }) => {
                onTimeUpdate && onTimeUpdate(seconds)
            })

            player.current.on('ended', () => {
                onEnded && onEnded()
            })
        }

        return () => {
            player.current?.off('playing');
            player.current?.off('loaded');
            player.current?.off('pause');
            player.current?.off('timeupdate');
            player.current?.off('ended');
        }

    }, [videoId, autoplay, onTimeUpdate, onEnded, loop])

    return (
        <div className='vimeo-player'>
            <div className='vimeo-player-inner'>
                <div id="vimeo-player" style={{ position: "relative", width: '100%', maxWidth: '100%' }}>
                </div>
            </div>
        </div>
    )
}

export default VimeoPlayer;
